.footer {
   min-height: 150px;
   border-top: 2px solid #F2F2F2 ;
   padding-top: 30px;
  
}



.footer_span {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem;
    border-right: 2px solid #F2F2F2 

}


.footer_span a {
  text-decoration: none;
  color: #116AAB;
  font-weight: 250;

}

.footer-at-fao{
   margin-right: 20px;
   margin-left: 20px;
   text-align: right;
   font-size: 0.9rem;
   color: rgb(74, 73, 73);
}

