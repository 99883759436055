.article {
    height:500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: top;
  }
  
  .header {
    font-size: 16px;
    color: white;
    text-align: left;
    font-weight: 500;
  }


  .homepage-row {
    background-color: #F2F2F2 !important;
  }

  .homepage-row  p {
    font-size: small;
  }

  .contact-box {
    margin-top: 3rem;
    background-color: #E5ECF4;
    padding: 1rem;
  }