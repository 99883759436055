
 
.alartContent {
    font-size: 0.9rem !important;
    font-weight: 200;
}


.accordion-AlartContent h6{
    font-weight: 300;
    font-size: 1rem;
}
 


.accordion-AlartContent .accordion-button{
background-color: #F2F2F2 !important;
}
 .accordion-button:not(.collapsed) {
    color: black  !important;

  
  }

 

  .alart-subtitle {
    font-size: 0.9rem;
    font-weight: 400; 
  }

  .alart-examples-country {
    text-decoration: underline;  
    text-decoration-color: black;
    font-size: 0.9rem;
    font-weight: 200;  
  }

  .alart-text-title a {
    color: #116AAB;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .alart-examples-data {
    font-size: 0.9rem;
    font-weight: 200;
  }



.no-arrow .accordion-button.collapsed::after  {

  background-image: none !important;

}

.no-arrow .accordion-button:not(.collapsed)::after {
  background-image: none !important;


} 




.intro .accordion-button.collapsed {

  background-color: #E5ECF4;

}

.intro .accordion-button:not(.collapsed){
  background-color: #E5ECF4;



}  

.alart-tabs li button {
color:#116AAB;
font-size: medium;
}

.download-icon {
  margin-top: 1rem;
}


.see-more-see-less {
  border: none;
  background-color: white;
  color: #116AAB !important;
  margin-bottom: 2rem;
  margin-left: 3rem;
  margin-right: 3rem
}


.accordion-intro .accordion-button.collapsed  {
border:none;
background-color: white!important;
font-weight: 200;
font-size: medium;
line-height: 1.5;
}

.accordion-intro .accordion-button:not(.collapsed) {
  border:none;
  background-color: white!important;
  box-shadow: none;
  font-weight: 200;
  font-size: medium;
  line-height: 1.5;
  }


  .accordion-intro .accordion-item  {
    border: none !important;
    background-color: white!important;
    font-size: medium;
    line-height: 1.5;
    }


  .accordion-intro .accordion-body  {
    font-weight: 200;
    font-size: medium;
    line-height: 1.5;
    padding-top: 0px;
    }
    

    .intro-paragraph  {
      font-weight: 200 !important;
      font-size: medium;
      line-height: 1.5;
      padding-top: 0px;
      }


    
  