
 /* .button:active, 
button:active,
.button:focus, 
button:focus,
.button:hover, 
button:hover {
   border:none !important;
   outline:none !important;
}
     */
/* .alartPage .make-it-active .accordion-button {
  color: black !important;
 
} */

.make-it-active {
  color: black;
  background-color: #F2F2F2;
}

.alart-side-bar-text {
  padding: 0.2rem;
}

.alartPage .accordion-item .accordion-button {
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
    background-color: #E5ECF4;
    color: grey;
    font-size: small;
  }


  .alartPage .accordion-item {
    border:none !important;
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
 
  }


  .alartPage .accordion {
    --bs-accordion-border-color : #E5ECF4 !important;
   
   
 
  }

 .accordion-item {
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
 
  }

  .accordion button:focus {
    box-shadow: none;
    color: black;

  }


  .alartPage .accordion-item {
   border-radius: 0rem!important;
   border: none;
   background-color: #E5ECF4;
  }
 

  .alartPage .accordion {
    background-color: #E5ECF4;
   }

   .alartPage .accordion-body {
    background-color:#E5ECF4;
    
   }
 
  

   .alartPage .no-padding .accordion-button{
  padding-top: 0px !important;
  padding-bottom: 3px !important;

}
   
.alartPage  .accordion-button:not(.collapsed) {
  color: black  !important;
  /* background-color: red !important; */
  padding-bottom: 0px;

}

.alartPage  .accordion-button:not(.collapsed)#text {

  background-color: red !important;


}


 .accordion-button:not(.collapsed)::after  {

  background-image: var(--bs-accordion-btn-icon) !important;

}


.alartPage .no-arrow .accordion-button.collapsed::after  {

  background-image: none !important;

}

.alartPage .no-arrow .accordion-button:not(.collapsed)::after {
  background-image: none !important;


} 

.arabic-fix .accordion-button::after {
  margin-right: auto;
  margin-left: 0px;
}

.normative btn {
  font-family: var(--bs-font-sans-serif) !important;
  font-size: small;
}


