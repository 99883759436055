.side-menu-bar {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
 /*    font-family: "Helvetica", "sans-serif"; */
}

.side-menu-bar li{
    margin-bottom: 0.7rem;
   list-style-image: url(../../public/img/chevron-right.svg);
   margin-left: 1rem;
  
   
}

.arabic-chevron.side-menu-bar li{
   list-style-image: url(../../public/img/chevron-left.svg);  
}


ul {
    list-style-position: inside;
    list-style-type: disc;
    font-size: 12px;
    line-height: 1.4em;
    padding: 0 1em;
  }
  
  ul li {
    margin: 0 1em 0 1em;
    padding: 0 1em 0 1em;
     text-indent: -2em; 
  
  }


.side-menu-bar .make-link-active {
    color: black !important;
}


.side-menu-bar a {
    text-decoration: none;
    color: grey;
    font-size: small;
    font-weight: 400;
    font-family: inherit;
}


.side-menu-bar h6 {
    text-decoration: none;
    font-size: small;
    font-weight: 600;
}


.side-menu-bar a:focus {
    color: black;
}

.side-menu-bar a:hover {
    color: black;
}

/* .treaties-table tbody td:nth-of-type(odd){background-color:#F2F2F2}
.treaties-table tbody td:nth-of-type(even){background-color:white} */

th {
    vertical-align: top;
    font-size: medium;
    font-weight: 500;

} 

 tbody td {
    font-size: medium;
    font-weight: 300;
    padding: 0.5rem !important

} 



.treaties-page-title {
    font-size: large;
    font-weight: 200;
    margin-top: 2rem;
    margin-bottom: 2rem;
  /*   font-family: "Helvetica", "sans-serif"; */
}

.argument-intro {
    background-color: rgb(229, 236, 244);
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 2rem;
 
   /*  font-family: "Helvetica", "sans-serif"; */

}

.argument-intro h5 {
font-size: large;
font-weight: 200;


}

.argument-intro p {
    font-size: small;
    font-weight: 300;
    }